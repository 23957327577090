<template>
  <div>

    <el-row class="mt-5">
      <el-col :span="4">
        <el-switch
            style="display: block"
            v-model="searchDto.isJd"
            active-color="#13ce66"
            inactive-color="#409eff"
            active-text="厂供商品"
            inactive-text="京东商品"
            :active-value="2"
            :inactive-value="1">
        </el-switch>
      </el-col>
    </el-row>
    <el-row class="mt-3" :gutter="20">
      <el-col :span="4">
        <el-input
            placeholder="请输入SkuId"
            prefix-icon="el-icon-search"
            v-model="searchDto.skuId">
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button @click="searchGoodsPriceBySkuId" type="primary">搜索</el-button>
      </el-col>
      <el-col v-if="searchVo.goodsInfoVos && searchVo.goodsInfoVos.length > 0" :span="4">
        <label class="fw-bold">市场价最大差: </label> <label class="text-success me-2"> {{searchVo.priceDiff}}</label>
        <label class="fw-bold">分销价最大差: </label> <label  class="text-primary"> {{searchVo.supplyPriceDiff}}</label>
      </el-col>
    </el-row>

    <el-row v-if="searchVo.goodsInfoVos && searchVo.goodsInfoVos.length > 0" class="mt-3">
      <el-table
          :span-method="mergeSameDataTable"
          :data="searchVo.goodsInfoVos"
          style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="商品名称">
                <span>{{ props.row.title }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
            label="商品 SkuID"
            prop="skuId">
          <template slot-scope="scope">
            <el-button @click="viewPriceTrend(scope.row)">{{ scope.row.skuId }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
            label="商品信息"
            prop="title"
            width="300px">
          <template slot-scope="props">
            <a :href="props.row.jdUrl" target="_blank" style="text-decoration: none; color: inherit;">
              <el-row>
                <el-col :span="8">
                  <span style="font-size: 12px;">{{ props.row.title }}</span>
                </el-col>
                <el-col :span="8">
                  <el-image
                      style="width: 100%; height: 100%; float: right;"
                      :src="props.row.picUrl"
                      :preview-src-list="[props.row.picUrl]"
                      fit="cover"
                  >
                  </el-image>
                </el-col>
              </el-row>
            </a>
          </template>
        </el-table-column>
        <el-table-column
            label="现在的市场价"
            prop="currentPrice"
        >
          <template slot-scope="props">
            <span> {{ props.row.currentPrice | format }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="修改的市场价"
            prop="currentSupplPrice">
          <template slot-scope="props">
            <span> {{ props.row.updatedMarketupPrice | format }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="现在的分销价"
            prop="currentSupplPrice">
          <template slot-scope="props">
            <span> {{ props.row.currentSupplPrice | format }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="修改的分销价"
            prop="currentSupplPrice">
          <template slot-scope="props">
            <span> {{ props.row.updatedSupplyPrice | format }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="修改的时间"
            prop="currentSupplPrice">
          <template slot-scope="props">
            <span> {{ props.row.updatedCreateTime | parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="修改的时间"
            prop="currentSupplPrice">
          <template slot-scope="scope">
            <el-button @click="viewPriceTrend(scope.row)">查看商品折线图</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="mt-10" justify="end">
      <el-button class="" size="small" type="primary" @click="getAllGoodsPriceTrendApi">刷新图表</el-button>
      <div style="width: 100vw; height: 100vh;"
           element-loading-text="拼命加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(0, 0, 0, 0.8)"
           v-loading="chartLoading">
        <ECharts3D
            :chartData="chartData"
            @skuIdCopied="handleSkuIdCopied"
        />
      </div>
    </el-row>

    <EChartsDialog
        :visible.sync="chartDialogVisible"
        :chartData="onlyChartData"
        @chartDialogVisibleFn="chartDialogVisibleFn"
    />
  </div>
</template>

<script>

import {getAllGoodsPriceTrendApi, getGoodsPriceTrendApi, getOnlyGoodsPriceTrendApi} from "@/api/admin/topicApi";
import ECharts3D from "@/components/3DLineChart/index.vue";
import EChartsDialog from '@/components/LineChart/index.vue'
export default {
  //mixins: [poolMixins, brandMixins, categoryMixins],
  components: {EChartsDialog, ECharts3D},
  name: 'GoodsPriceTrend',
  data() {
    return {
      chartData: {},
      searchDto: {
        skuId: '',
        isJd: 1,
        current: 1,
        size: 10
      },
      searchVo: {
        goodsInfoVos: []
      },
      chartLoading: false,

      chartDialogVisible: false,
      onlyChartData: {}
    }
  },
  computed: {},
  methods: {
    /**
     *
     * @returns {Promise<void>}
     */
    async searchGoodsPriceBySkuId() {
      if (!this.searchDto.skuId || !this.searchDto.isJd) {
        this.$message.error("请输入SkuId");
        return ;
      }
      let {data} = await getOnlyGoodsPriceTrendApi(this.searchDto);
      this.searchVo = data;

    },
    async handleSkuIdCopied(skuId) {
      // 在这里处理接收到的 SKU ID
      this.searchDto.skuId = skuId; // 如果需要，可以将 SKU ID 赋值给父组件的 skuId
      await this.searchGoodsPriceBySkuId()
    },
    /**
     * 查询所有商品的价格历史记录
     */
    async getAllGoodsPriceTrendApi() {
      this.chartLoading = true;
      let {data} = await getAllGoodsPriceTrendApi();
      this.chartData = data;
      this.chartLoading = false;
    },
    /**
     * 合并单元格
     */
    mergeSameDataTable({ row, column, rowIndex, columnIndex }) {
      // 合并 ID 和 SKU 列
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 5 || columnIndex === 8) {
        if (rowIndex > 0 &&  this.searchVo.goodsInfoVos[rowIndex].id === this.searchVo.goodsInfoVos[rowIndex - 1].id) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else {
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.searchVo.goodsInfoVos.length; i++) {
            if (this.searchVo.goodsInfoVos[i].id === this.searchVo.goodsInfoVos[rowIndex].id) {
              rowspan++;
            } else {
              break;
            }
          }
          return {
            rowspan,
            colspan: 1
          };
        }
      }
    },
    /**
     * 查看单个商品的折线图
     */
    async viewPriceTrend(item) {
      let {data} = await getGoodsPriceTrendApi({skuId: item.skuId});
      this.onlyChartData = {
        ...data,
        skuName: item.title
      };
      this.chartDialogVisible = true;
    },
    /**
     * 关闭弹窗
     */
    chartDialogVisibleFn(val){
      this.chartDialogVisible = val;
    },
  },
  created() {
    this.getAllGoodsPriceTrendApi();
  },
  activated() {

  },
  destroyed() {
  },
  deactivated() {

  },
}
</script>
<style>
#topicGoodsForm div label.el-form-item__label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

#topicGoodsForm .el-form-item {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .exit-row {
  background: #f9ebed;
}
</style>