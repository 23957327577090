import {
    copyTopicApi,
    createTopicApi,
    delTopicApi,
    editSaveTopicApi,
    editSaveTopicKeywordApi,
    getSelectionListApi
} from "@/api/admin/topicApi";
import Goods from "@/views/SelectGoods/components/Goods/index.vue";
import GoodsChange from "@/views/SelectGoods/components/GoodsChange/index.vue";

/**
 * 选品库
 */
export const mixins = {
    components: {Goods,GoodsChange},
    name: "SelectGoods",
    data() {
        return {
            keywordsVisible: false,
            params: {
                current: 1,
                size: 10,
                title: "",
                goodsId: "",
                goodsTitle: "",
                grouping: 1
            },
            total: 400,
            input: '',
            tableData: [],
            item: {},
            keywords: "",
            topicId: "",
            dialogEditFormVisible: false,
            formEdit: {
                id: "",
                title: "",
                grouping: ""
            },
            editRules: {
                title: [{required: true, message: '请输入选品库名称', trigger: 'blur'}],
                grouping: [{required: true, message: '请输入分组', trigger: 'change'}]
            }
        };
    },
    methods: {
        /**
         * 打开选品库的商品变化
         */
        openGoodsChangeByTopicId(item) {
            this.$refs.goodsChange.open(item);
        },
        /**
         * 创建选品库
         */
        createTopic() {
            this.$prompt('请输入选品库名称', '创建选品库', {
                inputValue: '',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async ({value}) => {
                if (!value.trim() === "") {
                    this.$message({
                        type: 'warning',
                        message: "选品库名称不能为空!"
                    });
                }
                await createTopicApi({
                    title: value
                })
                this.$message({
                    type: 'success',
                    message: "创建成功"
                });
                await this.getList();
            })
        },
        /**
         * 获取选品列表
         * @returns {Promise<void>}
         */
        async getList(current) {
            if (current) {
                this.params.current = 1;
            }
            let {data} = await getSelectionListApi(this.params)
            this.tableData = data.rows;
            this.total = data.total;
        },
        /**
         * 获取商品改变的数据
         */
        async getGoodsChangeList(current) {
            if (current) {
                this.params.current = 1;
            }
            let {data} = await getSelectionListApi(this.params)
            this.tableData = data.rows;
            this.total = data.total;
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.params.size = val;
            this.getList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.params.current = val;
            this.getList();
        },
        /**
         * 编辑选品
         */
        async editTopic() {
            let data = await editSaveTopicApi(this.formEdit)
            if (data.code === 0) {
                this.$message({
                    type: 'success',
                    message: "修改成功"
                });
            } else {
                this.$message({
                    type: 'error',
                    message: data.message
                });
            }

            await this.getList();
            this.dialogEditFormVisible = false;
        },
        openEditTopic(item) {
            this.dialogEditFormVisible = true;
            this.formEdit.id = item.id
            this.formEdit.grouping = item.grouping
            this.formEdit.title = item.title
        },
        /**
         * 打开商品管理页面
         * @param item
         */
        openTopicInfo(item) {
            this.$refs.goods.open(item)
        },
        /**
         * 复制选品库
         * @param item
         */
        copyTopic(item) {
            this.$prompt('请输入新选品库名称', '复制选品库', {
                inputValue: item.title,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async ({value}) => {
                if (!value.trim() === "") {
                    this.$message({
                        type: 'warning',
                        message: "选品库名称不能为空!"
                    });
                }
                await copyTopicApi({
                    id: item.id,
                    title: value
                })
                this.$message({
                    type: 'success',
                    message: "修改成功"
                });
                await this.getList();
            })
        },
        /**
         * 删除选品库
         * @param item
         */
        deleteTopic(item) {
            this.$confirm('你确定删除当前选品库吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await delTopicApi({
                    id: item.id
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                await this.getList();
            })
        },
        updateKeywords(item) {
            this.keywords = item.keywords
            this.topicId = item.id;
            this.keywordsVisible = true
        },
        async saveKeyword() {
            let {data} = await editSaveTopicKeywordApi({
                topicId: this.topicId,
                keywords: this.keywords
            })
            this.keywordsVisible = false
            await this.getList();

        }
    },
    mounted() {
        /**
         * 初始化列表
         */
        this.getList()
    }
}