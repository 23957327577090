import service from "@/utils/requestAdmin";

/**
 * 获取一级分类
 * @returns {*}
 */
export function addGoodsLowestBuyApi(data) {
    return service({
        url: '/goods/addGoodsLowestBuy',
        method: 'post',
        data
    })
}

export function getGoodsLowestBuyListApi(params) {
    return service({
        url: '/goods/lowestBuyList',
        method: 'get',
        params
    })
}

export function goodByLowestBuyTotalApi(params) {
    return service({
        url: '/goods/goodByLowestBuyTotal',
        method: 'get',
        params,
    })
}

export function goodListByLowestBuyApi(params) {
    return service({
        url: '/goods/goodListByLowestBuy',
        method: 'get',
        params,
    })
}

export function deleteGoodsLowestBuyApi(params) {
    return service({
        url: '/goods/deleteLowestBuy',
        method: 'delete',
        params
    })
}

export function searchJdVopGoodsByConditionApi(params) {
    return service({
        url: '/download/searchJdGoods',
        method: 'get',
        params
    })
}

export function getBrandListApi(params) {
    return service({
        url: '/download/brand',
        method: 'get',
        params
    })
}
