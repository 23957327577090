<template>

  <div ref="chart3d" style="width: 90%; height: 90%;"></div>

</template>

<script>

import 'echarts-gl';
import * as echarts from "echarts";

export default {
  name: 'ECharts3D',
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rate: 1, // 默认的加价率,
      mouseHover: false,
      searchSkuId: ""
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    rate() {
      // 当 rate 改变时，重新渲染图表
      this.$nextTick(() => {
        this.initChart();
      });
    }
  },
  methods: {
    initChart() {
      if (!this.chartData){
        return;
      }
      const chartDom = this.$refs.chart3d;
      if (chartDom) {
        const myChart = echarts.init(chartDom);

        let data = this.chartData.seriesDataList;

        let series = data.flatMap(item => {
          const lineSeries = {
            type: 'line3D',
            name: item.skuId,
            data: item.data.map(entry => [
              entry[0], // 时间
              parseFloat(entry[1]), // 分销价
              parseFloat(entry[2]), // 市场价
              item.skuId
            ]),
            itemStyle: {
              color: item.color,
            },
            showSymbol: false,
          };

          const scatterSeries = {
            type: 'scatter3D',
            name: item.skuId,
            data: item.data.map(entry => [
              entry[0],
              parseFloat(entry[1]),
              parseFloat(entry[2]),
              item.skuId
            ]),
            symbol: 'emptyCircle',
            symbolSize: 10,
            itemStyle: {
              color: '#808080',
            },
          };
          return [lineSeries, scatterSeries];
        })

        let option = {
          title: {
            text: '全部商品价格趋势图'
          },

          tooltip: {
            formatter: function (params) {
              const skuId = params.value[3] || params.seriesName;
              const color = params.color || '#000';
              const marketPrice = params.value[1].toFixed(2);
              const distributionPrice = params.value[2].toFixed(2);
              const time = params.value[0];
              return `
            <div class="fw-bold">点击可搜索该商品的价格变化</div>
            <div style="color: ${color}">
            SkuId: ${skuId}</div>
            <div>时间: ${time}</div>
            <div>市场价: ${marketPrice}</div>
            <div>分销价: ${distributionPrice}</div>
          `;
            },

          },
          backgroundColor: '#fff',
          xAxis3D: {
            type: 'category',
            data: this.chartData.xAxisTime,
            name: '时间'
          },
          yAxis3D: {
            type: 'value',
            name: '分销价',
          },
          zAxis3D: {
            type: 'value',
            name: '市场价',
          },
          grid3D: {
            viewControl: {
              projection: 'orthographic'
            }
          },
          series: series
        };
        myChart.setOption(option);
        window.addEventListener('resize', myChart.resize);

        // 添加点击事件监听
        myChart.on('click', (params) => {
          if (params.seriesType === 'line3D' || params.seriesType === 'scatter3D') {
            // 复制 params.value[3] 或者 params.name
            const valueToCopy = params.value[3] || params.name;
            this.copyToClipboard(valueToCopy);
            this.$message.success(`复制SkuId成功: ${valueToCopy}`);
            this.$emit('skuIdCopied', valueToCopy);
          }
        });

        // 鼠标悬停其他的都改变颜色
        myChart.on('mouseover', function (params) {
          if (params.seriesType === 'line3D' || params.seriesType === 'scatter3D') {
            myChart.setOption({
              series: series.map((s) => {
                if (s.name != params.seriesName) {
                  return {
                    ...s,
                    itemStyle: {
                      ...s.itemStyle,
                      opacity: 0.2  // 改变散点的透明度
                    },
                    lineStyle: s.type === 'line3D' ? {
                      ...s.lineStyle,
                      opacity: 0.2  // 改变折线的透明度
                    } : s.lineStyle
                  };
                } else {
                  return {
                    ...s,
                    itemStyle: {
                      ...s.itemStyle,
                      opacity: 1  // 改变散点的透明度
                    },
                    lineStyle:  {
                      ...s.lineStyle,
                      opacity: 1  // 改变折线的透明度
                    }
                  };
                }
                // return s;
              })
            });
          }
        });

        // 鼠标离开，恢复颜色
        myChart.getDom().addEventListener('mouseleave', function () {
          // 鼠标移出时，恢复所有系列的透明度和颜色
          myChart.setOption({
            series: series.map((s) => ({
              ...s,
              itemStyle: {
                ...s.itemStyle,
                opacity: 1  // 恢复散点的透明度
              },
              lineStyle: {
                ...s.lineStyle,
                opacity: 1  // 恢复折线的透明度
              }
            }))
          });
        });

      }
    },
    /**
     * 复制到剪切板的工具方法
     */
    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

    },
    /**
     * 弹窗关闭时 触发
     */
    chartDialogVisible() {
      this.$emit('chartDialogVisibleFn', false)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.myChart?.resize);
    }
  }
};
</script>
<!-- 3D-->
<!--initChart() {
const chartDom = this.$refs.chart3d;
if(chartDom){
const myChart = echarts.init(chartDom);

let data = this.chartData.seriesDataList;

const option = {
title: {
text: '全部商品价格趋势图' // 后端返回的标题
},
legend: {
data:  this.chartData.legendData // 动态传入 legend 数据
},
tooltip: {},
backgroundColor: '#fff',
visualMap: {
show: false,
dimension: 2,
// min: Math.min(...this.chartData.marketPrice),
// max: Math.max(...this.chartData.marketPrice),
inRange: {
color: [
'#313695',
'#4575b4',
'#74add1',
'#abd9e9',
'#e0f3f8',
'#ffffbf',
'#fee090',
'#fdae61',
'#f46d43',
'#d73027',
'#a50026'
]
}
},
xAxis3D: {
type: 'category',
data: this.chartData.xAxisTime,
name: '时间'
},
yAxis3D: {
type: 'value',
name: '市场价'
},
zAxis3D: {
type: 'value',
name: '分销价'
},
grid3D: {
viewControl: {
projection: 'orthographic'
}
},
series: [
{
type: 'line3D',
data: data,
lineStyle: {
width: 4
}
}
]
};

myChart.setOption(option);
window.addEventListener('resize', myChart.resize);
}
},-->
