
import {getCategory1ListApi, getCategoryListApi} from "@/api/admin/categoryApi";
import ObjectUtil from "@/utils/ObjectUtil";
import {addGoodsLowestBuyApi, deleteGoodsLowestBuyApi, getGoodsLowestBuyListApi, goodByLowestBuyTotalApi, goodListByLowestBuyApi,} from "@/api/admin/goodsApi";



/**
 * 选品商品
 */
export const mixins = {
    name: "GoodsLowestBuy",
    data() {
        return {
            rows: [],
            totalCount: 99,
            loading: false,
            ids: [],
            id: "",
            rate: 1.3,
            lowestBuyDto: {
                current: 1,
                size: 10,
                skuId: "",
                lowestBuy: "",
                isJd: 1,
                title: ""
            },
            addLowestBuyDtoList :[],
            addLowestBuyDto: {
                id: "",
                skuId: "",
                lowestBuy: "",
                isJd: "",
                initialLowestBuy: 1
            },
            goodsDialog: false,
            goodsLoading: false,
            firstPage: true,
            selectionGoods: {
                topicId: 664,
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
                priceSort: "",
                commentOkCount: "",
            },
            categoryId1: [],
            categoryId2: [],
            selectGoods: [],
            total: 999,
            itemArr: [],
            addLowestBuyDialog: false,
        };
    },
    // 方法
    methods: {
        // 复选框 将id 组成集合
        handleSelectionChange(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.skuId)
            })
            this.ids = arr;
        },
        /*重置*/
        reset() {
            this.lowestBuyDto = {
                current: 1,
                size: 10,
                isJd: 1
            };
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        statusHandleSizeChange(val) {
            this.lowestBuyDto.size = val;
            this.getLowestBuyList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        statusHandleCurrentChange(val) {
            this.lowestBuyDto.current = val;
            this.getLowestBuyList();
        },
        /**
         * 获取商品最低售卖数量列表
         */
        async getLowestBuyList() {
            this.loading = true;
            let {data} = await getGoodsLowestBuyListApi(this.lowestBuyDto)
            this.totalCount = data.total;
            this.rows = data.rows;
            this.loading = false;
        },

        async open() {
            this.selectionGoods.current = 1;
            this.selectionGoods.topicId = 664;
            this.goodsDialog = true;
            await this.initCategory();
            await this.getList()
        },
        /**
         * 初始化分类
         */
        async initCategory() {
            let {data} = await getCategoryListApi();
            this.categoryId1 = data.categoryList;
        },
        /**
         * 获取二级分类
         */
        async getCategory1List() {
            let {data} = await getCategory1ListApi({
                name: this.selectionGoods.categoryId1Name, categoryLevel: 1
            });
            this.selectionGoods.categoryId2Name = ""
            this.categoryId2 = data.categoryList;
            this.getList()
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.selectionGoods.size = val;
            this.getList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.selectionGoods.current = val;
            this.getList();
        },
        /**
         * 获取商品列表
         */
        async getList() {
            this.goodsLoading = true;
            this.selectionGoods.topicId = 664;
            let obj = JSON.parse(JSON.stringify(this.selectionGoods));
            obj.minProfitMargin = obj.minProfitMargin / 100;
            obj.maxProfitMargin = obj.maxProfitMargin / 100;
            ObjectUtil.removeEmptyValues(obj)
            let {data} = await goodListByLowestBuyApi(obj)
            this.selectGoods = data.rows;
            this.goodsLoading = false;
            let result = await goodByLowestBuyTotalApi(obj)
            this.total = result.data;

            this.firstPage = false;
        },
        /**
         * 获取选择商品ID
         * @param val
         */
        handleSelectionSkuIdChange(val) {
            this.itemArr = val.map(item => item)
        },

        /**
         * 打开添加商品起售数量的弹窗
         */
        openAddLowestBuyDialog(item) {
            this.addLowestBuyDtoList = [];
            if (item && this.itemArr.length <= 0) {
                this.addLowestBuyDto = {
                    id: "",
                    skuId: item.skuId,
                    lowestBuy: item.lowestBuy,
                    initialLowestBuy: item.lowestBuy, // 最低起购数量
                    isJd: 1,
                    title: item.title,
                    picUrl: item.picUrl,
                }
                this.addLowestBuyDtoList.push(this.addLowestBuyDto)
            } else if (this.itemArr.length > 0) {
                this.itemArr.forEach(item => {
                    this.addLowestBuyDtoList.push({
                        id: "",
                        skuId: item.skuId,
                        lowestBuy: item.lowestBuy,
                        initialLowestBuy: item.lowestBuy, // 最低起购数量
                        isJd: 1, // 后续会根据 商品页面的isJd来改变
                        title: item.title,
                        picUrl: item.picUrl,
                    })
                })
            }
            this.addLowestBuyDialog = true;
        },
        /**
         * 将商品限制起购数量中
         */
        async addGoodsLowestBuy(){
            if (this.addLowestBuyDtoList.length <= 0) {
                this.$message({
                    message: "请选择商品",
                    type: "warning",
                });
                return
            }

            await addGoodsLowestBuyApi(this.addLowestBuyDtoList);

            this.getLowestBuyList();
            this.addLowestBuyDialog = false;
            this.$message({
                message: "编辑成功",
                type: "success",
            });
        },
        checkLowestBuy(item) {
            if (!item || item <= 0) {
                this.$message.error(`起售数量不能低于：${item.initialLowestBuy}`);
                item.lowestBuy = item.initialLowestBuy;
            }
            if (item.lowestBuy < item.initialLowestBuy) {
                this.$message.error(`起售数量不能低于${item.initialLowestBuy}`);
                item.lowestBuy = item.initialLowestBuy; // 将值重置为最低数量
            }
        },
        editLowestBuyDialog(item) {
            this.addLowestBuyDtoList = [];
            this.addLowestBuyDtoList = [{
                id: item.id,
                skuId: item.skuId,
                lowestBuy: item.customLowestBuy,
                initialLowestBuy: item.jdLowestBuy, // 最低起购数量
                isJd: 1, // 后续会根据 商品页面的isJd来改变
                title: item.title,
                picUrl: item.picUrl,
            }];
            this.addLowestBuyDialog = true;
        },
        async editLowestBuyStatus(item,status) {
            if (!status || !item.id || status <= 0 || item.id <= 0) {
                return;
            }
            this.$confirm('此操作将删除该商品自定义起售量，是否确认继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await deleteGoodsLowestBuyApi({lowestBuyId: item.id, status: status})
                if (status === 1) {
                    this.$message({
                        type: 'success',
                        message: '恢复成功!'
                    });
                } else {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }
                this.getLowestBuyList();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    },

    // 初始化这个方法
    created() {
        this.getLowestBuyList();
    },
}