import {commentType} from "@/utils/commentType";
import {getCategory1ListApi, getCategoryListApi} from "@/api/admin/categoryApi";
import {getListTopicApi, getListTopicCountApi, pushAllSelGoodsApi, updateGoodsPriceApi, getTopicListApi} from "@/api/admin/topicApi";
import ObjectUtil from "@/utils/ObjectUtil";
import SelGoods from "@/views/SelectGoods/components/SelGoods/index.vue";
import { editDisPriceApi } from "@/api/backend/goodsManageApi.js";

/**
 * 选品商品
 */
export const mixins = {
    name: "Goods",
    data() {
        return {
            dialog: false,
            item: {},
            firstPage: true,
            index: 0,
            selectionGoods: {
                topicId: "",
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
                priceSort: "",
                keywords: "",
                commentOkCount: "",
            },
            categoryId1: [],
            categoryId2: [],
            selectGoods: [],
            total: 999,
            loading: false,
            rate: 1.25,
            jdRate: 1,

            topicVisible: false,
            topicListVo: [],
            topicListDto: [],
            topicVo: {},
            topicDto: {},

        };
    },
    components: {SelGoods},
    methods: {
        closeTopicVisible() {
            this.topicVisible = false
            this.topicListVo = []
            this.topicListDto = []
        },
        /**
         * 查询选品库
         */
        async getListTopic() {
            let {data} = await getTopicListApi(this.topicDto);
            this.topicListVo = data;
        },
        async editDisPrice(item) {
            let skuIdList = item;
            if (this.selectGoods) {
                skuIdList = this.selectGoods.map(item => item.skuId);
            }
            let requestData = {
                "skuIdList": skuIdList
            };
            // await editDisPriceApi({skuId: item.skuId});
            await editDisPriceApi(requestData);
            this.getList();
        },
        tableRowClassName({row}) {
            if (((Number(row.supplPrice) * Number(this.rate) ) > Number(row.price ) * Number(this.jdRate)) || (Number(row.supplPrice) >= Number(row.price) * Number(this.jdRate)) ) {
                return 'success-row';
            }

            if ( ((Number(row.supplPrice) * Number(this.rate) ) > Number(row.price) ) || (Number(row.supplPrice) >= Number(row.price) ) ) {
                return 'warning-row';
            }
        },
        //  修改价格
        editRate() {
            if (this.rate) {
                this.rate = Number(this.rate);
                this.getList();
            }
            if (this.jdRate) {
                this.jdRate = Number(this.jdRate);
                this.getList();
            }
        },
        /**
         * 真一键挑选
         */
        selTopicPushGoodsAll() {
            let obj = JSON.parse(JSON.stringify(this.selectionGoods));
            obj.minProfitMargin = obj.minProfitMargin / 100;
            obj.maxProfitMargin = obj.maxProfitMargin / 100;
            if (this.item.keywords) {
                obj.keywords = this.item.keywords.trim().split(/\s+/);
            }
            this.loading = true;
            ObjectUtil.removeEmptyValues(obj)
            this.$confirm('一键挑选?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let {data} = await pushAllSelGoodsApi(obj);
                this.loading = false
                this.queryGetList();
                this.$message({
                    message: '一键挑选成功',
                    type: 'success'
                });
            }).catch(r => {
                this.loading = false;
            });
        },
        /**
         * 一键挑选
         */
        async selTopicCurrentGoodsAll() {
            for (const item of this.selectGoods) {
                item.disPriceType = 1;
                item.disPrice = 0;
                item.goodsId = item.id;
                item.topicId = this.item.id
                await updateGoodsPriceApi(item)
            }
            this.$message({
                message: '挑选成功',
                type: 'success'
            });
            this.queryGetList();
        },
        /**
         * 切换列表
         */
        switchGoods(index) {
            this.index = index;
            if (index == 0) {
                this.queryGetList();
            }
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.selectionGoods.size = val;
            this.getList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.selectionGoods.current = val;
            this.getList();
        },
        /**
         * 评论类型
         */
        commentType() {
            return commentType
        },
        /**
         * 条件查询
         */
        queryGetList() {
            this.firstPage = true;
            this.getList()
        },
        /**
         * 挑选商品
         */
        async setTopicCurrentGoodsId(item,type) {
            //  当前选品库
            if (type === 1) {
                item.topicId = this.item.id;
                item.topicIds = null;
            } else if (type === 2) {
                //  topicIdList 有值就查询 没值只是打开弹窗
                if (this.topicListVo <= 0 || this.topicListDto.length <= 0) {
                    // 选到多个选品库
                    this.topicVisible = true;
                    await this.getListTopic();
                    this.topicVo = item;
                    return;
                }
                item.topicIds = this.topicListDto;
                item.topicId = null;
            } else {
                this.$message({
                    message: '请先选择商品',
                    type: 'warning'
                });
                this.topicListDto = [];
                this.topicListVo = [];
                return;
            }
            item.disPriceType = 1;
            item.disPrice = 0;
            item.goodsId = item.id;
            await updateGoodsPriceApi(item)
            this.queryGetList();
            if (type === 2) {
                this.topicVisible = false;
            }
            this.topicListDto = [];
            this.topicListVo = [];
        },
        /**
         * 查询
         */
        async getList() {
            this.loading = true;
            let obj = JSON.parse(JSON.stringify(this.selectionGoods));
            obj.minProfitMargin = obj.minProfitMargin / 100;
            obj.maxProfitMargin = obj.maxProfitMargin / 100;
            if (this.item.keywords) {
                obj.keywords = this.item.keywords.trim().split(/\s+/).join(",");
            }
            ObjectUtil.removeEmptyValues(obj)
            let {data} = await getListTopicApi(obj)
            this.selectGoods = data.rows;
            this.loading = false;
            if (this.firstPage) {
                let {data} = await getListTopicCountApi(obj)
                this.total = data[0]
            }
            this.firstPage = false;
        },
        /**
         * 初始化组件
         */
        open(item) {
            this.index = 0;
            this.item = item;
            this.selectionGoods.current = 1;
            this.selectionGoods.topicId = item.id;
            this.dialog = true;
            this.initCategory();
            this.queryGetList()
        },
        /**
         * 初始化分类
         */
        async initCategory() {
            let {data} = await getCategoryListApi();
            this.categoryId1 = data.categoryList;
        },
        /**
         * 获取二级分类
         */
        async getCategory1List() {
            let {data} = await getCategory1ListApi({
                name: this.selectionGoods.categoryId1Name, categoryLevel: 1
            });
            this.selectionGoods.categoryId2Name = ""
            this.categoryId2 = data.categoryList;
            this.queryGetList()
        },
        /**
         * 切换商品货源
         */
        isJdChange() {
            this.selectionGoods.current = 1;
            this.queryGetList()
        },
        /**
         * 关闭弹窗事件
         */
        closeChange() {
            this.$emit("closeChange");
        },
        /**
         * 重置
         */
        reset() {
            this.selectionGoods = {
                topicId: this.selectionGoods.topicId,
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
            }
            this.queryGetList();
        }
    },
    mounted() {

    }
}