import axios from 'axios'
import {Message} from 'element-ui'
import {getToken} from '@/utils/auth'
import router from "@/router";

// create an axios instance
const serviceAdmin = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 50000 // request timeout
})
serviceAdmin.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['Authorization'] = getToken()
            if (config.method === 'post' && config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers['Content-Type'] = 'application/json;charset=UTF-8';
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

serviceAdmin.interceptors.response.use(
    response => {
        const res = response.data
        if (res.errorCode) {
            Message({
                message: res.errMessage || 'Error',
                type: 'error',
                duration: 5 * 1000,
                offset: 60
            })
            return Promise.reject(res)
        }
        return res;
    },
    error => {
        Message.closeAll();
        if (error.response.data.msg.indexOf("用户未登录") >= 0) {
            Message({
                message: "你的登录状态已经失效,请重新登录！",
                type: 'error',
                duration: 5 * 1000,
                offset: 60
            })
            router.push('/login');
        } else {
            Message({
                message: error.response.data.msg,
                type: 'error',
                duration: 5 * 1000,
                offset: 60
            })
        }
        return Promise.reject(error)
    }
)

export default serviceAdmin
