<template>
  <el-dialog
      :visible.sync="myVisable"
      :title="`${skuName}`"
      width="50%"
      @close="chartDialogVisible">
    <!-- 在右上角添加 rate 输入框 -->
    <div style="text-align: right;">
       加价：
      <el-input-number
          v-model="rate"
          :min="0"
          :step="0.1"
          size="small"
          style="width: 120px;"
      />
    </div>
    <div id="echart-container" style="width: 100%; height: 400px;"></div>
  </el-dialog>
</template>

<script>



import * as echarts from "echarts";

export default {
  name: 'EChartsDialog',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      skuName: "",
      rate: 1, // 默认的加价率,
      myVisable: false
    };
  },
  watch: {
    visible(newVal) {
      this.myVisable = newVal
      if (newVal) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    chartData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    rate() {
      // 当 rate 改变时，重新渲染图表
      this.$nextTick(() => {
        this.initChart();
      });
    }
  },
  methods: {
    initChart() {
      const chartDom = document.getElementById('echart-container');
      if(chartDom){
        const myChart = echarts.init(chartDom);

        const { legendData, xaxisData, seriesDataList } = this.chartData;

        this.skuName = this.chartData.skuName;
        // 动态生成 series
        const series = legendData.map((legend) => {
          let seriesData;

          // 判断 legend 是否是分销价
          if (legend === '销售价') {
            // 如果 legend 是 "销售价"，根据 "分销价" 和 rate 动态计算
            seriesData = seriesDataList['分销价'].map(price =>  (price * this.rate).toFixed(2));
          } else {
            seriesData = seriesDataList[legend];
          }

          return {
            name: legend,
            type: 'line',
            data: seriesData
          };
        });

        // 如果 rate 不等于 1，并且 legendData 不包含 "销售价"，则动态添加
        if (this.rate !== 1 && !legendData.includes('销售价')) {
          legendData.push('销售价');
          series.push({
            name: '销售价',
            type: 'line',
            data: seriesDataList['分销价'].map(price => (price * this.rate).toFixed(2)) // 根据 rate 动态计算销售价
          });
        }

        const option = {
          title: {
            text: this.chartData.text || '商品价格趋势图' // 后端返回的标题
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: legendData // 动态传入 legend 数据
          },
          xAxis: {
            type: 'category',
            data: xaxisData // 时间数据
          },
          yAxis: {
            type: 'value'
          },
          series // 动态生成的 series 数组
        };

        myChart.setOption(option);
      }
    },
    /**
     * 弹窗关闭时 触发
     */
    chartDialogVisible(){
      this.$emit('chartDialogVisibleFn',false)
    }
  }
};
</script>
