import EChartsDialog from '@/components/LineChart/index.vue'
import {getCategory1ListApi, getCategoryListApi} from "@/api/admin/categoryApi";
import {getGoodsChangePageApi, getGoodsPriceTrendApi, updateGoodsPriceApi} from "@/api/admin/topicApi";


/**
 * 选品商品
 */
export const mixins = {
    name: "GoodsChange",
    components: {
        EChartsDialog
    },
    data() {
        return {
            dialog: false,
            item: {},
            firstPage: true,
            index: 0,
            total: 999,
            loading: false,
            goodsChangeDto: {
                topicId: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                minProfitMargin: "",
                maxProfitMargin: "",
                commentOkCount: "",
                logisticsType: "",
            },
            categoryId1: [],
            categoryId2: [],
            goodsChangeVo: [],
            idArr: [],
            rate: 1.3,
            jdRate: 1,
            chartDialogVisible: false,
            chartData: {}
        };
    },
    methods: {
        /**
         * 重新添加商品到选品库
         */
        async reAdd(item) {
            item.disPriceType = 1;
            item.disPrice = 0;
            item.goodsId = item.id;
            await updateGoodsPriceApi(item)
            this.queryGetList();
        },
        /**
         * 查看商品价格趋势
         */
        async viewPriceTrend(item) {
            let {data} = await getGoodsPriceTrendApi({skuId: item.skuId});
            this.chartData = {
                ...data,
                skuName: item.title
            };
            this.chartDialogVisible = true;
        },
        chartDialogVisibleFn(val){
            this.chartDialogVisible = val;
        },
        /**
         * 获取表格样式
         */
        tableRowClassName({row}) {
            if (row.changStatus == 1) {
                return 'success-row';
            }
            if (row.changStatus == 2) {
                return 'exit-row';
            }
        },
        /**
         *  放大图片
         */
        getSrcList(cardPicUrl) {
            if (cardPicUrl) {
                return cardPicUrl.split(',');
            }
        },
        /**
         * 初始化组件
         */
        open(item) {
            this.index = 0;
            this.item = item;
            this.goodsChangeDto.current = 1;
            this.goodsChangeDto.topicId = item.id;
            this.dialog = true;
            this.initCategory();
            this.queryGetList()
        },
        /**
         * 关闭弹窗事件
         */
        closeChange() {
            this.$emit("closeChange");
        },
        /**
         * 初始化分类
         */
        async initCategory() {
            let {data} = await getCategoryListApi();
            this.categoryId1 = data.categoryList;
        },
        /**
         * 获取二级分类
         */
        async getCategory1List() {
            let {data} = await getCategory1ListApi({
                name: this.goodsChangeDto.categoryId1Name, categoryLevel: 1
            });
            this.goodsChangeDto.categoryId2Name = ""
            this.categoryId2 = data.categoryList;
            this.queryGetList()
        },
        /**
         * 条件查询
         */
        queryGetList() {
            this.getList()
        },
        /**
         * 获取选择商品ID
         * @param val
         */
        handleSelectionChange(val) {
            this.idArr = val.map(item => item.id)
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.goodsChangeDto.size = val;
            this.getList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.goodsChangeDto.current = val;
            this.getList();
        },
        /**
         * 查询
         */
        async getList() {
            this.loading = true;
            let config = {
                ...this.goodsChangeDto,
                sortOrder: this.goodsChangeDto.sortOrder ? this.goodsChangeDto.sortOrder.join(',') : ''
            }
            let obj = JSON.parse(JSON.stringify(config));
            let {data: res} = await getGoodsChangePageApi(obj)
            this.goodsChangeVo = res.rows;
            // let {data} = await getSelectedTotalApi(obj)
            this.total = res.total;
            this.loading = false;
        },
        /**
         * 重置
         */
        reset() {
            this.goodsChangeDto = {
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
            }
            this.queryGetList();
        }
    },
    mounted() {

    }
}