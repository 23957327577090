<template>
  <div style="background: #f5f8fa">
    <div
        id="layout"
        style="width: 100%;height: 50px;background: rgb(43, 54, 67);display: flex;align-content: center;align-items: center;">
      <div style="width: 225px;">
        <img alt="logo" class="img-responsive" src="../../assets/img/logo.png"
             style="margin-left: 8px;"/>
      </div>
      <div :class="this.$route.fullPath==='/selectGoods'||this.$route.fullPath==='/'?'actives':''"
           style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/selectGoods">选品专区</router-link>
      </div>
      <div :class="this.$route.fullPath==='/idc'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/idc">数据中心</router-link>
      </div>
      <div :class="this.$route.fullPath==='/SpareSelectGoods'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/SpareSelectGoods">备用选品库</router-link>
      </div>
      <div :class="this.$route.fullPath==='/combo'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/combo">组合礼包</router-link>
      </div>
      <div :class="this.$route.fullPath==='/ECard'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/ECard">卡券</router-link>
      </div>
      <div :class="this.$route.fullPath==='/Order'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/Order">订单</router-link>
      </div>
      <div :class="this.$route.fullPath==='/XiTunBao'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/XiTunBao">喜豚宝</router-link>
      </div>
<!--      <div :class="this.$route.fullPath==='/XiTunBaoOrder'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/XiTunBaoOrder">喜豚宝订单</router-link>
      </div>
      <div :class="this.$route.fullPath==='/XiTunBaoCard'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/XiTunBaoCard">喜豚宝消费卡</router-link>
      </div>-->
      <div :class="this.$route.fullPath==='/XiTunBaoKdlOrder'?'actives':''" style="padding: 11px 18px;font-size:14px">
        <router-link style="color: #b4bcc8" to="/XiTunBaoKdlOrder">喜豚宝订单(砍对了)</router-link>
      </div>
      <div class="logout-container">
        <el-popconfirm
            title="确定要退出登录吗？"
            confirm-button-text="确定"
            cancel-button-text="取消"
            icon="el-icon-warning"
            icon-color="red"
            @confirm="signOut"
        >
          <a href="javascript:void(0)" slot="reference" class="logout-link">退出登录</a>
        </el-popconfirm>
      </div>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import NavComponent from "@/components/NavComponent";
import {getToken,removeToken} from "@/utils/auth";


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NavComponent,
  },
  computed: {},
  methods: {
    async signOut() {
      // 清除 Token
      removeToken();

      // 你可能还需要清除其他与用户相关的信息，比如 Vuex 状态或本地存储
      // this.$store.dispatch('logout'); // 如果有 Vuex logout action

      // 手动重定向到登录页面
      this.$router.push('/login');
    }
  },

  created() {
    if (getToken()=='undefined'){
      this.$router.push("/login");
    }
  },
};
</script>
<style lang="scss">
#layout {
  .actives {
    color: white !important;
    background: #1caf9a;
  }

  .actives a {
    color: #fff !important;
  }

  .nav-style {

  }
}


.logout-container {
  position: fixed;
  top: 10px;
  right: 20px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 4.5vh; /* 使容器的高度为视口高度 */
}

.logout-link {
  color: white;
  font-size: 14px;
  padding: 11px 18px;
  border-radius: 4px; /* 可选：按钮的圆角 */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* 添加过渡效果 */
}

/* 鼠标悬停时 */
.logout-link:hover {
  background-color: red;
  color: white;
}

</style>
