<template>
  <div style="padding-top: 10px">

    <div style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px">
      <div v-show="moreConditions">
        <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;align-items: center;">
          <el-form id="params" :model="params">
            <el-row :gutter="20">
              <el-col :span="4">
                <el-form-item label="商品名称">
                  <el-input v-model="params.keyword" placeholder="请输入" style="width: 100%">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="品牌名称">
                  <el-select v-model="params.brandName"
                             multiple
                             filterable
                             remote
                             reserve-keyword
                             placeholder="请输入关键词"
                             :remote-method="getBrandList">
                    <el-option
                        v-for="(item, index) in brandList"
                        :key="index"
                        :label="item.brandName"
                        :value="item.brandName">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="一级分类">
                  <el-select v-model="params.categoryId1" placeholder="请选择" style="width: 100%"
                             @change="getCategory1List(1)">
                    <el-option label="请选择" value="">
                    </el-option>
                    <el-option
                        v-for="(item,index) in categoryId1"
                        :key="index"
                        :label="item.fullName"
                        :value="item.categoryId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="二级分类">
                  <el-select v-model="params.categoryId2" placeholder="请选择" style="width: 100%"
                             @change="getCategory1List(2)">
                    <el-option label="请选择" value="">
                    </el-option>
                    <el-option
                        v-for="(item,index) in categoryId2"
                        :key="index"
                        :label="item.fullName"
                        :value="item.categoryId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="三级分类">
                  <el-select v-model="params.categoryId3" placeholder="请选择" style="width: 100%">
                    <el-option label="请选择" value="">
                    </el-option>
                    <el-option
                        v-for="(item,index) in categoryId3"
                        :key="index"
                        :label="item.fullName"
                        :value="item.categoryId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="排序规则">
                  <el-select v-model="params.sortType" placeholder="请选择" style="width: 100%">
                    <el-option
                        v-for="(item,index) in sortTypeList"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <el-button type="primary" @click="searchJdVopGoodsByCondition(1)" size="small">搜索
                </el-button>
              </el-col>
              <el-col :span="1">
                <el-button type="danger" @click="resetListParams" size="small">重置
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button type="primary" plain @click="moreConditions = !moreConditions" size="small">搜索条件
            <i v-if="moreConditions" class="el-icon-arrow-up"></i>
            <i v-if="!moreConditions" class="el-icon-arrow-down"></i>
          </el-button>
        </div>
        <div class="fw-bold" style="margin-left: 10px;margin-bottom: 10px">
          加价率：
          <el-input v-model="rate" size="small"></el-input>
        </div>
        <div class="fw-bold" style="margin-left: 10px;margin-bottom: 10px">
          已搜索次数：{{ params.satisfyPageIndex - 1 }}；
          <span v-if="params.satisfyPageIndex - 1 === params.totalSearchNum" style="color: red; margin-left: 10px;">
            已是最后一页
          </span>
          <span v-else style="margin-left: 10px;">
            将要搜索的页数：{{ params.satisfyPageIndex }}；
          </span>
          <span style="margin-left: 10px;">总共要搜索次数：{{ params.totalSearchNum }}。</span>
        </div>
      </div>
      <el-table
          v-loading="loading"
          @selection-change="handleSelectionSkuIdChange"
          :data="rows"
          style="width: 100%">

        <el-table-column
            type="selection"
            width="30">
        </el-table-column>
        <el-table-column
            :align="'center'"
            label="商品SkuId"
            prop="skuId"
            width="100px">
        </el-table-column>
        <el-table-column
            :align="'center'"
            label="商品信息"
            prop="cardName"
            width="300px">
          <template slot-scope="scope">
            <div v-if="scope.row.picUrl.indexOf('/Uploads') ==0">
              <div class="d-flex align-items-center">
                <div class="symbol symbol-50px me-5">
                  <img :src="`https://www.1c-1g.cn${scope.row.picUrl}`"
                       alt="" class="">
                </div>
                <div class="d-flex justify-content-start flex-column">
                  <a :href="`${scope.row.jdUrl}`"
                     class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                     target="_blank">{{ scope.row.skuName }}</a>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-flex align-items-center">
                <div class="symbol symbol-50px me-5">
                  <img :src="scope.row.picUrl">
                </div>
                <div class="d-flex justify-content-start flex-column">
                  <a :href="`https://m.jd.com/product/${scope.row.skuId}.html`"
                     class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                     target="_blank">{{ scope.row.skuName }}</a>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="分类信息"
            prop="goodsName"
            width="110px"
            :show-overflow-tooltip="true">
          <template slot-scope="scope">
                  <span class="text-muted fw-bold text-muted d-block fs-7">
                    {{ scope.row.categoryName1 ? scope.row.categoryName1 : "" }}
                    <br>
                    {{ scope.row.categoryName2 ? scope.row.categoryName2 : "" }}
                    <br>
                    {{ scope.row.categoryName3 ? scope.row.categoryName3 : "" }}
                  </span>
          </template>
        </el-table-column>
        <el-table-column
            label="市场价"
            prop="price"
            width="100">
          <template slot-scope="scope">
            {{ scope.row.price | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="成本价"
            prop="disPrice"
            width="100">
          <template slot-scope="scope">
            {{ scope.row.disPrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="销售价"
            width="100">
          <template slot-scope="scope">
            {{ (scope.row.disPrice * rate).toFixed(2) | format }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="70">
          <template slot-scope="scope">
            {{
              scope.row.status == 1 ? '上架' : '下架'
            }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="运费"
            width="80">
          <template slot-scope="scope">
            <a class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{
                (scope.row.discount
                    * 10)
              }}折</a>
          </template>
        </el-table-column>
        <el-table-column
            label="起售数量"
            prop="lowestBuy"
            width="176">
        </el-table-column>
        <el-table-column
            align="center"
            label="配送方式"
            width="100">
          <template slot-scope="scope">
            {{ scope.row.logisticsType == 1 ? '京东' : '其他' }}
          </template>
        </el-table-column>
        <el-table-column
            label="评论数"
            prop="commentOkCount"
            width="100">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="addJdGoods(scope.row)">添加到数据库</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 50, 100, 200]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>

  </div>
</template>
<script>

import {searchJdVopGoodsByConditionApi, getBrandListApi} from "@/api/admin/goodsApi.js";
import {getCategory1ListApi, getCategoryListApi} from "@/api/admin/categoryApi";
import {downloadDataByCustomizeApi} from "@/api/download/brandApi";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SearchJdGoods",
  data() {
    return {
      // 更多查询
      moreConditions: false,
      rate: 1.3,
      itemArr: [],
      rows: [],
      total: 0,
      loading: false,
      params: {
        current: 1,
        satisfyPageIndex: 1,
        totalSearchNum: 0,
        size: 10,
        brandName: "",
        keyword: "",
        maxPrice: "",
        minPrice: "",
        categoryId1: "",
        categoryId2: "",
        categoryId3: "",
        sortType: 1
      },
      categoryId1: [],
      categoryId2: [],
      categoryId3: [],
      sortTypeList: [{
        label: "销量降序",
        value: 1
      }, {
        label: "价格升序",
        value: 2
      }, {
        label: "价格降序",
        value: 3
      }, {
        label: "评论数降序",
        value: 4
      }, {
        label: "上架时间降序",
        value: 5
      }, {
        label: "15天销售额",
        value: 6
      }, {
        label: "按15日销量降序",
        value: 7
      }, {
        label: "按30日销量降序",
        value: 8
      }, {
        label: "按15日销售额降序",
        value: 9
      }, {
        label: "按30日销售额排序",
        value: 10
      }],
      brandList: []
    }
  },
  methods: {
    handleSelectionSkuIdChange(val) {
      this.itemArr = val.map(item => item)
    },
    /**
     * 添加数据到数据库中
     */
    async addJdGoods(item) {
      if (item) {
        this.itemArr = [];
        this.itemArr.push(item);
      }
      if (this.itemArr.length <= 0) {
        this.$message.error("请选择要添加的数据");
        return;
      }
      await downloadDataByCustomizeApi(this.itemArr);
      this.$message.success("添加成功");
    },
    /**
     * 搜索品牌
     */
    async getBrandList(name) {
      let {data} = await getBrandListApi({name: name});
      this.brandList = data;
    },
    /**
     * 搜索商品
     */
    async searchJdVopGoodsByCondition(item) {
      if (item === 1) {
        this.params.satisfyPageIndex = 1;
      }
      this.loading = true;
      let {data} = await searchJdVopGoodsByConditionApi(this.params);
      const total = data.total; // 从后端获取的总记录数
      const size = this.params.size; // 每页的记录数

      this.params.satisfyPageIndex = data.pages;
      this.total = total;


      // 计算总页数
      this.params.totalSearchNum = total % size === 0 ? total / size : Math.floor(total / size) + 1;
      // data.total % this.params.size == 0 ? data.total / this.params.size : data.total / this.params.size + 1;
      if (!data.rows || data.rows.length === 0) {
        this.rows = [];
        this.loading = false;
        this.$message.error("没有更多数据了");
        return;
      }
      this.rows = data.rows;
      this.loading = false;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.searchJdVopGoodsByCondition();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.searchJdVopGoodsByCondition();
    },
    /**
     *  重置列表查询参数
     */
    resetListParams() {
      this.params = {
        current: 1,
        size: 10,
        satisfyPageIndex: 1,
        brandName: "",
        keyword: "",
        maxPrice: "",
        minPrice: "",
        categoryId1: "",
        categoryId2: "",
        categoryId3: "",
        sortType: 1
      };
      this.searchJdVopGoodsByCondition();
    },
    /**
     * 初始化分类
     */
    async initCategory() {
      let {data} = await getCategoryListApi();
      this.categoryId1 = data.categoryList;
    },
    /**
     * 获取二级分类
     */
    async getCategory1List(level) {
      if (level === 1) {
        let {data} = await getCategory1ListApi({
          name: this.params.categoryId1, categoryLevel: level
        });
        this.categoryId2 = data.categoryList;
      } else if (level === 2) {
        let {data} = await getCategory1ListApi({
          name: this.params.categoryId2, categoryLevel: level
        });
        this.categoryId3 = data.categoryList;
      }
      this.queryGetList()
    },
  },
  created() {
    this.searchJdVopGoodsByCondition();
    this.initCategory();
  }
}
</script>